<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				input: {},
			}
		},
		computed: {
			mrValidation() {
				return this.$root.config.mrValidation.forgot_password
			}
		},
		async mounted() {
			await this.ready();
			setTimeout(() => { 
				SEMICOLON.header.init();
				SEMICOLON.widget.extras(); }, 300)
			this.$set(this.$root, 'page', this);
		},
		methods: {
			onSubmit(e) {
				if(e&&e.btnLoading()) return;
				Gen.apirest('/forgot-password', this.input, (err, resp) => {
					if(e) e.btnUnloading()
					if (err) return this.$refs.info.show(err.responseJSON.message, "warning")
					// this.$refs.info.show(resp.message, "success")
					swal(resp.message, "","success")
					this.$router.push({
						name: "Login"
					})
				}, 'POST')
			},
		},
	};
</script>
<template>
	<section id="content" style="background-color: #000; overflow: visible">
		<div class="content-wrap pt-0 pb-0">
			<div id="section-about" class="section page-section bg_0f181b nobottommargin notopmargin clearfix">
				<div class="container clearfix">
					<div class="row  justify-content-center dark">
						<div class="col-md-6 col-sm-6 col-lg-5">
							<div class="wrap_login">
								<h2>{{web.lbl_forgot_password}}</h2>
								<h4>{{web.lbl_enter_your_email}}</h4>
								<VForm @resp="onSubmit">
								<VInfo ref="info"></VInfo>
								<div class="row">
									<div class="col-md-12 mb-2">
										<label class="lbl_field">
											<span class="icon-line2-envelope"></span>
										</label>
										<div class="form-group">
											<input type="email" name="email" v-model="input.email" onkeydown="return emailKey(event)" v-bind="validation('email')" class="frm_custom_sign padleft_form" value="" placeholder="Email Address">
											<LabelError name="email"></LabelError>
										</div>
									</div>
									<div class="col-md-12 text-center">
										<p><button type="submit" class="btn_line w_100 btn-loading">{{web.lbl_forgot_password}} <i class="icon-line-arrow-right"></i></button></p>
									</div>
									<div class="col-md-12">
										<div class="divider divider-short  mb-3 divider-center">{{web.lbl_or}}
										</div>
									</div>
									<div class="col-md-12">
										<p class="text-center">
											<router-link :to="{name:'Login'}" class="borbot">
												{{web.lbl_login}}
											</router-link>
										</p>
									</div>
								</div>
								</VForm>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="clear"></div>
		</div>
	</section>
</template>